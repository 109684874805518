import { request } from './request'

export function getBeatFeedBack (params) {
  return request({
    methods: 'GET',
    url: '/ActGiftDetail' + params,
  })
}

export const KingMealInfoApi = (data) => {
  return request({
    url: '/KingMealInfo',
    method: 'get',
  })
}

export const KingMealInfolApi = (data) => {
  return request({
    url: '/KingMealInfol',
    method: 'get',
  })
}
//趣味竞猜
export const TasteMealInfoApi = (data) => {
  return request({
    url: '/TasteMealInfo ',
    method: 'get',
  })
}

export const getdata = (params) => {
  return request({
    method: 'GET',
    url: '/ActGiftDetail' + params,
  })
}

export const Actapply = (data) => {
  return request({
    method: 'post',
    url: '/VipActapply',
    data,
  })
}

export const usersApi = (data) => {
  return request({
    url: '/users_v2',
    method: 'post',
    data,
  })
}
// init信息
export const initApi = (data) => {
  return request({
    url: '/init',
    method: 'get',
  })
}

// 错误返回的内容传过去
export const CatchExceptApi = (data) => {
  return request({
    url: '/CatchExcept',
    method: 'post',
    data,
  })
}

export const vipActapply = (data) => {
  return request({
    url: '/VipActapply',
    method: 'post',
    data
  })
}

export const verificationCodesApi = (data) => {
  return request({
    url: '/verificationCodes',
    method: 'post',
    data,
  })
}

export const uservipinfoApi = (data) => {
  return request({
    url: '/uservipinfo' + data,
    method: 'get',
  })
}

export const areacodeApi = (data) => {
  return request({
    url: '/areacode',
    method: 'get',
  })
}
