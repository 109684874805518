<template>
  <div class="chessHand" style="padding-bottom: 20px">
    <div v-title data-title="六一存款加赠"></div>

    <!-- <van-nav-bar
      title="欢乐棋牌"
      left-arrow
      :fixed="true"
      @click-left="gotoback"
    /> -->
    <div class="imgbox">
      <!-- 五一图  -->
      <!-- <img :src="`${imgUrl}pcbb/bg-LabourDay.png`" /> -->
      <!-- 六一图 -->
      <img :src="`${imgUrl}pcbb/bg-LabourDay2.png`" />
    </div>

    <div class="rule-big-box">
      <div class="rule-top-img">
        <img src="../assets/img/pcbb/rules_1.png" v-if="cueTheme == 'dattheme'" />
        <img :src="`${imgUrl}pcbb/rules.png`" v-else />
      </div>
      <div class="rule-middle-img">
        <!-- 活动条件 -->
        <div class="rule-condition">
          <div class="rule-condition-title"></div>
          <div class="rule-acontents">
            <div class="title-item">
              <p>活动对象:</p>
              <span style="color:var(--li-colortext);">贝宝体育全站会员</span>
            </div>
            <div class="title-item">
              <p>指定场馆:</p>
              <span style="color:var(--li-colortext);">所有游戏场馆(彩票除外)</span>
            </div>
            <div class="title-item">
              <p>活动时间:</p>
              <span style="color:var(--li-colortext);">2023-06-01 00:00:00 ～ 2023-06-03 23:59:59</span>
            </div>
            <div class="title-item">
              <p>参与方式:</p>
              <span style="color:var(--li-colortext);">存款后（只可领取一次）即可参加，联系在线客服领取。</span>
            </div>
          </div>
        </div>

        <!-- 活动内容 -->
        <div class="rule-content">
          <div class="rule-content-title"></div>
          <table>
            <thead style=" border-top-left-radius: 10px; border-top-right-radius: 10px;">
              <tr
                style="background: linear-gradient(90deg, #4581fa, #63b3fc);border-top-left-radius: 10px; border-top-right-radius: 10px;">
                <th
                  style="color: #fff;border-right: 1px solid #e2d2bf;font-family: PingFang SC;font-weight: 400;border-top-left-radius: 10px;">
                  单笔存款
                </th>
                <th style=" color: #fff;border-right: 1px solid #e2d2bf;font-family: PingFang SC;font-weight: 400;">
                  彩金金额
                </th>
                <th style="color: #fff; border-top-right-radius: 10px">
                  流水倍数
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1000</td>
                <td>208</td>
                <td style="border-bottom: 1px solid #E9E9E9;" :rowspan="5">
                  6倍
                </td>
              </tr>
              <tr>
                <td>3000</td>
                <td>388</td>
              </tr>
              <tr>
                <td>5000</td>
                <td>688</td>
              </tr>
              <tr>
                <td>10000</td>
                <td>1688</td>
              </tr>
              <tr>
                <td>30000</td>
                <td>2688</td>
              </tr>
            </tbody>
          </table>

          <div class="btn-box">
            <div @click="applyWay">联系客服</div>
          </div>
        </div>

        <!-- 活动详情 -->
        <div class="rule-detail">
          <div class="rule-detail-title"></div>
          <div class="activity_rules">
            <ul>
              <li>
                <span>1.</span>
                指定场馆：所有游戏场馆(彩票除外)。
              </li>
              <li>
                <span>2.</span>
                活动期间每位会员只能申请一次。
              </li>
              <li>
                <span>3.</span>
                该优惠活动成功申请后不能取消，必须打满流水才能提款。
              </li>
              <li style="color:red">
                <span>4.</span>
                申请此活动时以最后一笔存款进行计算，并且必须带本金一起申请。
              </li>
              <li>
                <span>5.</span>
                在申请此优惠前，请您先完善真实姓名、手机号码、银行卡等个人信息。
              </li>
              <li>
                <span>6.</span>
                每位会员存款后申请此活动彩金，有效投注额达到【（充值金额+彩金）x6倍流水】即可提款。<br />
                计算公式如下：<br />
                例如：会员申请活动彩金：充值金额1000元，送彩金208元，有效投注额为：(1000+208)×6=7248
              </li>
              <li>
                <span>7.</span>
                若发现有套利客户，对冲或不诚实获取盈利之行为，将取消其优惠资格。
              </li>
              <li>
                <span>8.</span>
                本活动只针对娱乐性质的会员，同一手机号码、电子邮箱、相同银行卡、同一个IP地址、同一通信地址、同一台设备，只能由一位会员使用，若发现重复行为，将无限期保留审核、扣回礼品及所产生的利润之权利。
              </li>
              <li>
                <span>9.</span>
                本活动中任何用户或团体以不正常的方式套取活动优惠，我司保留在不通知的情况下冻结或关闭账号使用的权力，并不退还款项，且用户会被列入黑名单。
              </li>
              <li>
                <span>10.</span>
                为避免文字争议，此活动遵循一般活动规则与条款，并由贝宝保留最终解释权。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="rule-bottom-img"></div>
    </div>
  </div>
</template>

<script>
import { getdata } from '../network/api'
import { Toast } from 'vant'
export default {
  data () {
    return {
      datalist: [],
      isshow: false,
      timer: null,
      is_token: false,
      aa: { title: 'uni' },
      cueTheme: '',
    }
  },
  onShow () {
  },

  mounted () {
    if (localStorage.getItem('access_token')) {
      this.is_token = true
    } else {
      this.is_token = false
    }
    // this.getdata()
    this.cueTheme = window.localStorage.getItem('cueTheme')
  },

  methods: {
    gotoback () {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true',
            },
          })
        })
      }
    },

    getdata () {
      var _this = this
      var data = '?type=ChessHand'
      getdata(data).then((res) => {
        this.datalist = res.message
      })
    },
    changeisshow () {
      clearInterval(this.timer)
      this.isshow = true
      this.timer = setTimeout(() => {
        this.isshow = false
      }, 3000)
    },
    applyWay () {
      uni.getEnv((res) => {
        if (Object.keys(res)[0] === 'h5') {
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'gotoservice',
              },
            },
            '*'
          )
          // Toast('该活动仅在app开发,请下载APP')
        } else {
          // 申请
          uni.postMessage({
            data: {
              urlApply: 'gotoservice',
            },
          })
        }
      })
    },
    check () {
      uni.getEnv((res) => {
        if (Object.keys(res)[0] === 'h5') {
          Toast('该活动仅在app开发,请下载APP')
        } else {
          // 查看进度
          uni.postMessage({
            data: {
              urlApply: 'ChessHandCheck',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="less">
@import '../assets/css/rulestyle.less';

body {
  background-color: #f7f8fa;
}

.van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  color: #000 !important;
}

.van-nav-bar .van-icon {
  color: #000;
}

.chessHand {
  position: relative;
  font-family: PingFang SC;

  .btn-box {
    padding-bottom: 5px;

    >div {
      font-family: PingFang SC;
      font-weight: 400;

      &:nth-child(1) {
        height: 40px;
        color: #fff;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        background: linear-gradient(0deg, #2a6afd, #63c6ff);
        margin: 25px 0px 15px 0px;
      }

      &:nth-child(2) {
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #2a6afd;
        color: #418beb;
        margin: 15px 0px 15px 0px;
      }
    }
  }

  .rightbox {
    position: fixed;
    top: 150px;
    right: 0;
    z-index: 1;
    background: linear-gradient(0deg, #2a6afd, #63c6ff);
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    padding: 5px 5px 5px 0;
    color: #fff;

    .content {
      display: flex;
      align-items: center;

      >div {
        &:nth-child(1) {
          min-width: 54px;
          height: 54px;
          border: 4px solid #fff;
          border-radius: 50%;
          margin-left: 5px;
          margin-right: 5px;

          img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
          }
        }

        .primary_color {
          font-size: 11px;
          line-height: 1.2em;
        }
      }
    }
  }
}
</style>
